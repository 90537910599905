
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    name: "RadioInput",
    props: {
      value: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: ""
      },
      amplification: {
        type: String,
        default: ""
      },
      modelValue: {
        type: String,
        default: ""
      },
      options: {
        type: Array,
        default: () => []
      },
      required: {
        type: Boolean,
      },
      error: {
        type: String,
        default: ""
      },
    },
    emits: ["update:modelValue", "input", "change"],
    setup(props) {
    const checked = ref();

		return { checked };
	}
  });
