import * as yup from "yup";

export const getCentreRecognitionSchema = (section: any, contacts: any) => { 
    return {
        title: section.value.data.name,
        submitText: "Save",
        fields: [
            {
                name: "recognised_by_other_awarding_org",
                label: "Do you hold recognised centre status with any other Awarding Organisation?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.recognised_by_other_awarding_org,
                validation: yup.string().required()
            },
            {
                name: "recognised_by_other_awarding_org_details",
                label: "Please provide details",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.recognised_by_other_awarding_org_details,
                view: "all",
                validation: yup.string().when("recognised_by_other_awarding_org", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "refused_by_other_awarding_org",
                label: "Has your centre been refused centre recognition by another awarding organisation?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.refused_by_other_awarding_org,
                validation: yup.string().required()
            },
            {
                name: "refused_by_other_awarding_org_details",
                label: "Please provide details",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.refused_by_other_awarding_org_details,
                validation: yup.string().when("refused_by_other_awarding_org", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "sanction_or_restriction_by_other_awarding_org",
                label: "Has your centre been subject to any sanction or restriction imposed by any other awarding organisation?",
                amplification: "Examples of sanctions include but are not limited to removal of approval to deliver a qualification, suspension of staff members, removal of DCS.",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.sanction_or_restriction_by_other_awarding_org,
                validation: yup.string().required()
            },
            {
                name: "sanction_or_restriction_by_other_awarding_org_details",
                label: "Please provide details and current status",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.sanction_or_restriction_by_other_awarding_org_details,
                validation: yup.string().when("sanction_or_restriction_by_other_awarding_org", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "listed_directors",
                label: "Provide details of the individuals who are listed as directors of your company",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.listed_directors,
                validation: yup.string().required()
            },
            {
                name: "inspected_by",
                label: "Provide details of any external organisations that you are inspected by (e.g.Ofsted, HMI) and summarise the details of their most recent findings",
                amplification: "If not applicable, please give reason for why it does not apply.",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.inspected_by,
                validation: yup.string().required()
            },
            {
                name: "websites_of_adverts",
                label: "Provide details of any websites where the centre will advertise TQUK qualifications or their status as a TQUK centre",
                amplification: "Please provide the domain of any websites that will feature any TQUK affiliated programs or branding",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.websites_of_adverts,
                validation: yup.string().required()
            },
            {
                name: "resellers",
                label: "Provide the names of any organisation or individual which will be acting as a reseller for your centre, including website addresses and named contacts",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.resellers,
                validation: yup.string().required()
            },
            {
                name: "intend_to_offer_qualifications_overseas",
                label: "Does your centre intend to offer TQUK qualifications overseas?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.intend_to_offer_qualifications_overseas,
                validation: yup.string().required()
            },
            {
                name: "arrangements_and_documents_confirmation",
                label: "Do you have the following arrangements and/or documents in place and that they are up to date and appropriately communicated across the centre and any resellers",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.arrangements_and_documents_confirmation,
                validation: yup.string().required()
            },
            {
                name: "rectify_arrangements_and_documents",
                label: "Please show how you will rectify this",
                component: "TextInput",
                required: true,
                type: "text",
                value: section.value.data.rectify_arrangements_and_documents,
                validation: yup.string().when("arrangements_and_documents_confirmation", {
                    is: "false",
                    then: yup.string().required()
                })
            },
            {
                name: "policy_adoption_confirmation",
                label: "For any policies or procedures missing please confirm that you will adopt the relevant policy or procedure in the downloads section",
                component: "CheckboxInput",
                required: true,
                type: "text",
                value: section.value.data.policy_adoption_confirmation,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "confirmation",
                label: "Please tick to confirm that you agree with the principles for Staffing, Resources, Delivery and Assessment and IQA?",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.arrangements_and_documents_confirmation,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "declaration",
                label: "Declaration? Accurate, in line with Centre Agreement",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.declaration,
                validation: yup.boolean().required().isTrue()
            },
        ]
    }
}