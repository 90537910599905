import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed top-0 bg-brand px-16 w-full h-16 border-b flex items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMenu = _resolveComponent("NavMenu")!
  const _component_UserMenu = _resolveComponent("UserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavMenu),
    _createVNode(_component_UserMenu)
  ]))
}