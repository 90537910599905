
  import { defineComponent, ref } from "vue";
  import firebase from "@/firebase";
  import useAuth from "@/data/useAuth";

  export default defineComponent({
    name: "FileInput",
    props: {
      name: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: ""
      },
      amplification: {
        type: String,
        default: ""
      },
      modelValue: {
        type: String,
        default: ""
      },
      readonly: {
        type: Boolean,
      },
      required: {
        type: Boolean,
      },
      error: {
        type: String,
        default: ""
      },
    },
    emits: ["update:modelValue", "fileUpload", "input", "change", "blur"],
    setup(props, context) {
      const storage = firebase.storage();
      const showAmplification = ref(false);
      const showInput = ref(props.value ? false : true);
      const { hubspotUser } = useAuth();

      const changeFile = (() => {
        showInput.value = true;
      })

      const onChange = ((e: any) => {

          const file = e.target.files[0];
          const fileRef = storage.ref().child(`${hubspotUser.companyId}/${file.name}`);

          fileRef.put(file).then((snapshot) => {
            context.emit('update:modelValue', `${file.name}`); 
            context.emit('fileUpload', e); 
            context.emit('change'); 
          });
      })

      return { showAmplification, onChange, showInput, changeFile }
    }
  });
