
import { defineComponent, ref, Ref, watch, reactive, computed } from "vue";
import useStandards from "@/data/useStandards";
import useForms from "@/data/useForms";
import TextInput from "@/components/inputs/TextInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import FileInput from "@/components/inputs/FileInput.vue";
import Multiselect from '@vueform/multiselect';
import router from "@/router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import useAuth from "@/data/useAuth";
import * as yup from "yup";

export default defineComponent({
  name: "StandardSelection",
  components: {
    TextInput,
    SelectInput,
    CheckboxInput,
    FileInput,
    LoadingSpinner,
    Multiselect
  },
  emits: ["onClose", "onSubmit"],
    setup(props, context) {
      const { hubspotUser } = useAuth();
      const selections = ref([]);
      const { applyForStandards } = useStandards();
      const { updateFormSection } = useForms();

      const standards = ref([
        { title: "Adult Care Worker", value: "Adult Care Worker" },
        { title: "Advance and Creative Hair Professional", value: "Advance and Creative Hair Professional" },
        { title: "Assessor/Coach", value: "Assessor/Coach" },
        { title: "Associate Project Manager", value: "Associate Project Manager" },
        { title: "Business Administrator", value: "Business Administrator" },
        { title: "Children, Young People & Families Manager", value: "Children, Young People & Families Manager" },
        { title: "Children, Young People & Families Practitioner", value: "Children, Young People & Families Practitioner" },
        { title: "Customer Service Practitioner", value: "Customer Service Practitioner" },
        { title: "Customer Service Specialist", value: "Customer Service Specialist" },
        { title: "Digital Marketer", value: "Digital Marketer" },
        { title: "Early Years Educator", value: "Early Years Educator" },
        { title: "Early Years Practitioner", value: "Early Years Practitioner" },
        { title: "Early Years Lead Practitioner", value: "Early Years Lead Practitioner" },
        { title: "Hair Professional", value: "air Professional" },
        { title: "Healthcare Assistant Practitioner", value: "Healthcare Assistant Practitioner" },
        { title: "Healthcare Cleaning Operative", value: "Healthcare Cleaning Operative" },
        { title: "Healthcare Sector", value: "Healthcare Sector" },
        { title: "Healthcare Support Worker", value: "Healthcare Support Worker" },
        { title: "HR Consultant/Partner", value: "HR Consultant/Partner" },
        { title: "HR Sector", value: "HR Sector" },
        { title: "HR Support", value: "HR Support" },
        { title: "IT Technical Salesperson", value: "IT Technical Salesperson" },
        { title: "Lead Adult Care Worker", value: "Lead Adult Care Worker" },
        { title: "Lead Practitioner in Adult Care", value: "Lead Practitioner in Adult Care" },
        { title: "Leader in Adult Care", value: "Leader in Adult Care" },
        { title: "Learning and Development Consultant", value: "Learning and Development Consultant" },
        { title: "Learning and Development Practitioner", value: "Learning and Development Practitioner" },
        { title: "Learning and Skills Teacher", value: "Learning and Skills Teacher" },
        { title: "Learning Mentor", value: "Learning Mentor" },
        { title: "Operations / Departmental Manager", value: "Operations / Departmental Manager" },
        { title: "Sales Executive", value: "Sales Executive" },
        { title: "Senior Healthcare Support Worker", value: "Senior Healthcare Support Worker" },
        { title: "Teaching Assistant", value: "Teaching Assistant" },
        { title: "Team Leader / Supervisor", value: "Team Leader / Supervisor"}
    ]);

      const regions = ref([
        { title: "N/A", value: "N/A" },
        { title: "England (North East)", value: "England (North East)" },
        { title: "England (North West)", value: "England (North West)" },
        { title: "England (Yorkshire and the Humber)", value: "England (Yorkshire and the Humber)" },
        { title: "England (West Midlands)", value: "England (West Midlands)" },
        { title: "England (East Midlands)", value: "England (East Midlands)" },
        { title: "England (Eastern Region)", value: "England (Eastern Region)" },
        { title: "England (South West)", value: "England (South West)" },
        { title: "England (South East)", value: "England (South East)" },
        { title: "England (London)", value: "England (London)" },
    ]);

      const fields = ref([
            {
                name: "first_anticipated_gateway_dates",
                label: "First Anticipated Gateway Dates",
                component: "TextInput",
                type: "date",
                required: true,
                value: "",
                view: "all",
                validation: yup.date().required()
            },
            {
                name: "average_yearly_apprentices",
                label: "How many apprentices do you expect do you expect to submit to gateway, within an average year?",
                component: "TextInput",
                type: "number",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().required()
            },
            {
                name: "epa_regions",
                label: "Please confirm the regions with face to face End-Point Assessment requirements. If you have only remote assessment requirements, please enter N/A",
                component: "SelectInput",
                options: regions.value,
                type: "text",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "standards_with_specialisms",
                label: "If applying for standards with specialisms, please confirm the range provided. If applying for standards without a specialism, please enter N/A",
                component: "TextInput",
                type: "text",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().required()
            },
            {
                name: "standards_with_teaching_subject_requirements",
                label: "If applying for standards with teaching subject requirements, please confirm the range required. If applying for standards without teaching subject requirements, please enter N/A",
                component: "TextInput",
                type: "text",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().required()
            },
        ]);

      const declarationSchema = ref([
        {
            name: "complete_truthful_accurate_confirmation",
            label: "I confirm that the information provided is complete, truthful and accurate",
            component: "CheckboxInput",
            required: true,
            value: "",
            view: "all",
            validation: yup.boolean().required().isTrue()
        },
        {
            name: "update_changes_confirmation",
            label: "I confirm that we will update TQUK if there are any changes to the information above",
            component: "CheckboxInput",
            required: true,
            value: "",
            view: "all",
            validation: yup.boolean().required().isTrue()
        },
        {
            name: "register_apprentices_in_advance_confirmation",
            label: "I confirm that we will register all apprentices 6 months in advance of their expected gateway date",
            component: "CheckboxInput",
            required: true,
            value: "",
            view: "all",
            validation: yup.boolean().required().isTrue()
        },
        {
            name: "maintain_accuracy_of_expected_dates_confirmation",
            label: "I confirm that we will maintain the accuracy of all expected gateway dates for our apprentices",
            component: "CheckboxInput",
            required: true,
            value: "",
            view: "all",
            validation: yup.boolean().required().isTrue()
        },
      ])

      // Return standard record by id
      const getStandard = (title: string): any => {
        const selected = standards.value.filter((standard: any) => {
          return standard.value === title;
        });
        return selected[0];
      };

      watch(selections, () => {
        selections.value.forEach(selection => {
          formData[selection] = {values: {}, errors: {}};
        });
      })

      // Validation schema
      let validationSchema: any = reactive({});
      fields.value.forEach((field: any) => {
        validationSchema[field.name] = field.validation;
      });
      const formValidation = yup.object().shape(validationSchema);

      // Set all selections key/value for question
      const setAll = (field: any, val: any) => {
        // create a key value pair for field/value in every selections object

        console.log("setAll", field, val);
        
        selections.value.forEach(selection => {
          formData[selection].values[field] = val;

          console.log("validateAt", field, val);
        
          formValidation.validateAt(field, formData[selection].values)
            .then(() => {
              delete formData[selection].errors[field];
              delete formErrors[field];
            })
            .catch((err: any) => {
              formData[selection].errors[field] = err.message;
              formErrors[field] = err.message;
            })
            .finally(isFormValid);
          });

      }

      // Set a single selections key/value for question
      const setOne = (selection: any, field: any, val: any) => {
        // create a key value pair for the field/value in the selection object

        console.log("setOne", selection, field, val);

        formData[selection].values[field] = val;
        console.log("validateAt", field, val);

        formValidation.validateAt(field, formData[selection].values)
          .then(() => {
            delete formData[selection].errors[field];
            delete formErrors[field];
          })
          .catch((err: any) => {
              formData[selection].errors[field] = err.message;
              formErrors[field] = err.message;
          })
          .finally(isFormValid);
      }

      // Fires when input method changes
      const onChangeInputMethod = (field: string, value: any) => {
        selections.value.forEach(selection => {
          delete formData[selection].values[field];
          delete formData[selection].errors[field];
          delete formErrors[field];
          delete formValues[field];
          delete formValues[selection + field];
        });
        isFormValid();
      }

      const formValid: Ref<boolean> = ref(false);
      let formData: any = reactive({});
      const formValues: any = reactive({
        epa_regions: "0"
      });
      const formErrors: any = reactive({});

      const isFormValid = () => {
        selections.value.forEach(selection => {
          formValidation.validate(formData[selection].values, { abortEarly: true })
            .then(() => {
              formValid.value = true;
            })
            .catch((err: any) => {
              formValid.value = false;
            });
        });
      }

      const errorCount = computed(() => {
        let count = 0;
        selections.value.forEach((selection: any) => {
          count += Object.keys(formData[selection].errors).length;
        });
        return count;
      })

      const onClose = async () => {
        router.push(`/forms/2`);
      }

      const onSubmit = () => {
        selections.value.forEach(selection => {
          let data: any = reactive({});
          data.title = getStandard(selection).title;
          data.fields = formData[selection].values;
        
          applyForStandards(hubspotUser.companyId, data);
        });
        updateFormSection("2", "2", {status: 2, progress: 100}).then(() => {
            router.push(`/forms/2`).then(() => {
              setTimeout(function () { 
                router.go(0);
              }, 3000);
            });
        });
        router.push(`/forms/2`);
      }

      return { router, standards, selections, fields, declarationSchema, onClose, onSubmit, getStandard, setOne, setAll, onChangeInputMethod, formData, formValues, formErrors, errorCount, formValid };
    }
});

