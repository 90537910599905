
import { defineComponent } from "vue";

export default defineComponent({
	name: "LoadingSpinner",
	props: {
		message: {
			type: String
		}
	}
});
