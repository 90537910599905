import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-0 overflow-hidden"
}
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-y-0 right-0 pl-10 max-w-full flex"
}
const _hoisted_3 = { class: "w-screen max-w-4xl" }
const _hoisted_4 = { class: "h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, {
      "enter-active-class": "transition duration-300 ease-out",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "transition duration-150 ease-in",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0"
    }, {
      default: _withCtx(() => [
        (_ctx.router.currentRoute.value.meta.slideOut)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "absolute inset-0 bg-gray-900 bg-opacity-75 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.go(-1)))
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      "enter-active-class": "transition duration-500 ease-in-out transform",
      "enter-from-class": "translate-x-full",
      "enter-to-class": "translate-x-0",
      "leave-active-class": "transition duration-500 ease-in-out transform",
      "leave-from-class": "translate-x-0",
      "leave-to-class": "translate-x-full"
    }, {
      default: _withCtx(() => [
        (_ctx.router.currentRoute.value.meta.slideOut)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "default", {
                    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push(_ctx.router.currentRoute.value.meta.returnPath)))
                  })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 64))
}