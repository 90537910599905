import firebase from "../firebase";
const db = firebase.firestore();

export default function useCompanies() {

  const getCompany = async (companyId: string) => {
    const companyRef = db.collection("companies").doc(companyId);
    let company;
    await companyRef.get().then((doc) => {
        if (doc.exists) {
            company = doc.data();
        }
        else {
            throw new Error(`Cannot locate data for company with ID: ${companyId}`)
        }
    })
    return company;
  }
  return { getCompany };
}
