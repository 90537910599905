import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicForm = _resolveComponent("DynamicForm")!

  return (_openBlock(), _createBlock(_component_DynamicForm, {
    schema: _ctx.contactSchema,
    onOnSubmit: _ctx.onSubmit,
    onOnClose: _ctx.onClose
  }, null, 8, ["schema", "onOnSubmit", "onOnClose"]))
}