
import { defineComponent } from "vue";
import useForm from "@/use/useForm";
import router from "@/router";
import TextInput from "@/components/inputs/TextInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioInput from "@/components/inputs/RadioInput.vue";
import FileInput from "@/components/inputs/FileInput.vue";

export default defineComponent({
  name: "DynamicForm",
  components: {
    TextInput,
    SelectInput,
    CheckboxInput,
    RadioInput,
    FileInput
  },
  props: {
     schema: {
        type: Object,
        required: true
      }
  },
  emits: ["onClose", "onSubmit", "contactAdd"],
  setup(props, context) {
    const { formValid, formValues, formErrors, validateField } = useForm(props.schema.fields);

    const onClose = () => {
      context.emit('onClose')
    };

    const onSubmit = () => {
      context.emit("onSubmit", formValues);
    };

    const contactAdd = () => {
      context.emit("contactAdd");
    }

    return { router, formValid, formValues, formErrors, validateField, onClose, onSubmit, contactAdd};
  }
});
