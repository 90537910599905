import { reactive, toRef } from "vue";
import * as yup from "yup";

export default function useForm(fields: any): any {
    const form = reactive({
        valid: false,
        values: {},
        errors: {},
    });
    const formValid: any = toRef(form, "valid");
    const formValues: any = toRef(form, "values");
    const formErrors: any = toRef(form, "errors");
    let validationSchema: any = reactive({});

    fields.forEach((field: any) => {
        formValues.value[field.name] = field.value;
    })

    const getValidationSchema = () => {
        validationSchema = {};
        fields.forEach((field: any) => {
          if (field.logic) field.logic(form);
          if (!field.hidden) {
            validationSchema[field.name] = field.validation;
          }
        });
        return yup.object().shape(validationSchema);
      }

    const validateField = (field: string) => {
        getValidationSchema()
        .validateAt(field, formValues.value)
        .then(() => {
          delete formErrors.value[field];
        })
        .catch((err: any) => {
            formErrors.value[field] = err.message;
        });

        isFormValid();
    }

    const validateForm = () => {
        getValidationSchema().validate(formValues.value, { abortEarly: false })
          .then(() => {
            formErrors.value = {};
            console.log("Form Valid!");
          })
          .catch((err: any) => {
            err.inner.forEach((error: any) => {
              formErrors.value[error.path] = error.message;
            });
            console.log("Form Errors!");
          });
      }

    const isFormValid = () => {
        getValidationSchema().validate(formValues.value, { abortEarly: true })
          .then(() => {
            formValid.value = true;
          })
          .catch((err: any) => {
            formValid.value = false;
          });
      }

    const submitForm = () => {
        console.log("submitForm", form)
        validateForm();
    }

    return { formValid, formValues, formErrors, validateField, validateForm, submitForm}
}
