
import { defineComponent } from "vue";
import router from '@/router';
import TopBar from "@/components/TopBar.vue";
import SlideOut from "@/components/SlideOut.vue";

export default defineComponent({
  name: "FullLayout",
  components: {
    TopBar,
    SlideOut
  },
  setup() {
    return { router };
  },
});
