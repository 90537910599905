import { ref, Ref } from "vue";
import * as yup from "yup";

export const getQualificationApprovalSchema = (section: any, contacts: any) => { 

    const qualifications = ref([]);

    return {
        title: section.value.data.name,
        submitText: "Save",
        fields: [
            {
                name: "qualifications",
                label: "Please select the qualifications you wish to include as part of your application",
                component: "SelectInput",
                options: qualifications.value,
                type: "text",
                required: true,
                value: section.value.data.qualifications ? section.value.data.qualifications : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "annual_learner_registrations",
                label: "Annual Learner Registrations",
                component: "TextInput",
                type: "number",
                required: true,
                value: section.value.data.annual_learner_registrations,
                validation: yup.string().required()
            },
            {
                name: "first_registration_date",
                label: "First Registration Date",
                component: "TextInput",
                type: "date",
                required: true,
                value: section.value.data.first_registration_date,
                validation: yup.date().required()
            },
            {
                name: "qualification_contact",
                label: "Please select a specific qualification contact",
                component: "SelectInput",
                contactAddButton: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.qualification_contact ? section.value.data.qualification_contact :"0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "work_with_other_awarding_organisations",
                label: "Do you work with any other Awarding Organisations?",
                component: "SelectInput",
                options: [
                    { title: "End Point Assessor", value: "End Point Assessor" },
                    { title: "Internal Quality Assurer - End Point Assessment", value: "Internal Quality Assurer - End Point Assessment" },
                    { title: "External Quality Assurer - Of Regulated Qualifications", value: "External Quality Assurer - Of Regulated Qualifications" },
                ],
                type: "text",
                required: true,
                value: section.value.data.work_with_other_awarding_organisations ? section.value.data.work_with_other_awarding_organisations : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "awarding_organisations_with_dcs",
                label: "Please select any Awarding Organisations that you have DCS with",
                component: "SelectInput",
                options: [
                    { title: "End Point Assessor", value: "End Point Assessor" },
                    { title: "Internal Quality Assurer - End Point Assessment", value: "Internal Quality Assurer - End Point Assessment" },
                    { title: "External Quality Assurer - Of Regulated Qualifications", value: "External Quality Assurer - Of Regulated Qualifications" },
                ],
                type: "text",
                required: true,
                value: section.value.data.awarding_organisations_with_dcs ? section.value.data.awarding_organisations_with_dcs : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "provide_qualification_with_skills_network",
                label: "Do you provide this qualification with our partner The Skills Network and their EQUAL platform or resources?",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.provide_qualification_with_skills_network,
                validation: yup.boolean().required()
            },
            {
                name: "intend_to_deliver_qualification_outside_uk",
                label: "Do you intend to deliver this qualification outside of the UK?",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.intend_to_deliver_qualification_outside_uk,
                validation: yup.boolean().required()
            },
            {
                name: "qualification_delivery_methods",
                label: "Please confirm the delivery methods you will use for the qualification",
                component: "SelectInput",
                options: [
                    { title: "Online", value: "Online" },
                    { title: "Classroom", value: "Classroom" },
                    { title: "Blended", value: "Blended" },
                ],
                type: "text",
                required: true,
                value: section.value.data.qualification_delivery_methods ? section.value.data.qualification_delivery_methods : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "qualification_assessment_methods",
                label: "Please confirm the assessment methods you will use for the qualification",
                component: "SelectInput",
                options: [
                    { title: "Portfolio", value: "Portfolio" },
                    { title: "Observation", value: "Observation" },
                    { title: "Professional Discussion", value: "Professional Discussion" },
                ],
                type: "text",
                required: true,
                value: section.value.data.qualification_assessment_methods ? section.value.data.qualification_assessment_methods : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "qualification_iqa_strategy",
                label: "Please confirm the IQA strategy you will use for the qualification",
                component: "SelectInput",
                options: [
                    { title: "100% Sampling", value: "100% Sampling" },
                    { title: "20% Sampling", value: "20% Sampling" },
                    { title: "Observations", value: "Observations" },
                    { title: "Standardisation", value: "Standardisation" },
                ],
                type: "text",
                required: true,
                value: section.value.data.qualification_iqa_strategy ? section.value.data.qualification_iqa_strategy : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "iqa_strategy_additional",
                label: "Please provide additional information regarding your IQA Strategy",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.iqa_strategy_additional,
                validation: yup.date().required()
            },
            {
                name: "iqa_strategy_document",
                label: "Please attach your IQA Policy/Strategy document",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.iqa_strategy_document,
                validation: yup.date().required()
            },
            {
                name: "declaration",
                label: "Declaration? Accurate, In line with Centre Agreement",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.declaration,
                validation: yup.boolean().required().isTrue()
            },
        ]
    }

}