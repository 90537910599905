import { createApp, App as Application } from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "./firebase";
import "tailwindcss/tailwind.css";
import { clickoutside } from "./events";
import useAuth from "./data/useAuth";

let app: Application;
const auth = firebase.auth();

const startApplication = () => {
  if (!app) {
    app = createApp(App);
    app.directive("click-outside", clickoutside);
    app.use(router);
    app.mount("#app");
  }
}

auth.onAuthStateChanged(async (user) => {
  if (user) {
    const { getHubSpotUser } = useAuth();
    const firebaseEmail = user.email;

    await getHubSpotUser(firebaseEmail)
      .then(() => {
        startApplication();
      }).catch((err) => {
        console.log(err.message)
      })
  }
  else {
    startApplication();
  }
});