import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyA08by3b7eXZv7zhOerzn_hfrGBuWg2vtI",
//   authDomain: "tquk-forms-system-dev.firebaseapp.com",
//   projectId: "tquk-forms-system-dev",
//   storageBucket: "tquk-forms-system-dev.appspot.com",
//   messagingSenderId: "879548790991",
//   appId: "1:879548790991:web:e2c9d9d7bab34d000e1fba",
// };

// LIVE
const firebaseConfig = {
  apiKey: "AIzaSyC-hXoPINZyTr5VPgA0Q_nbvBz4wYmUwr0",
  authDomain: "tquk-form-system-prod.firebaseapp.com",
  projectId: "tquk-form-system-prod",
  storageBucket: "tquk-form-system-prod.appspot.com",
  messagingSenderId: "121497502460",
  appId: "1:121497502460:web:39e94dc3c3b11460ea4d79"
};

export default firebase.initializeApp(firebaseConfig);

// Use firebase emulators locally
if (window.location.hostname === "localhost") {
  //firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
  //firebase.firestore().useEmulator("localhost", 5002);
}
