
import { reactive, ref } from "vue";
import router from "../router";
import * as yup from "yup";
import firebase from "../firebase";
import useAuth from "@/data/useAuth";
import useForm from "@/use/useForm";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "SignIn",
  components: {
        LoadingSpinner
    },
  setup() {
    const auth = firebase.auth();
    const { getHubSpotUser } = useAuth();
    const signInError = ref("");
    const loading = ref(false);

    const schema = reactive({
      submitText: "Sign In",
      fields: [
        {
          name: "Email",
          label: "Email",
          type: "email",
          value: "",
          validation: yup.string().email().required()
        },
        {
          name: "Password",
          label: "Password",
          type: "password",
          value: "",
          validation: yup.string().required()
        }
      ]
    });

    const { formValid, formValues, formErrors, validateField } = useForm(schema.fields);

    const redirect = router.currentRoute.value.query.redirect as string;

    const signInUser = async () => {
      loading.value = true;
      auth
        .signInWithEmailAndPassword(formValues.value.Email, formValues.value.Password)
        .then(async (user) => {
          await getHubSpotUser(user.user?.email)
            .then(() => {
              router.push(redirect ? redirect : "/forms");
            }).catch((err) => {
              signInError.value = err.message;
            })
        })
        .catch((err) => {
          loading.value = false;
          signInError.value = err.message;
        });
    };

    const sendPasswordResetEmail = () => {
      auth.sendPasswordResetEmail(formValues.value.Email)
        .then(() => {
          alert("Password reset email has been sent.");
        })
        .catch((error) => {
          signInError.value = error.message;
        });
    }

    return { schema, signInUser, sendPasswordResetEmail, signInError, formValid, formValues, formErrors, validateField, loading };
  },
};
