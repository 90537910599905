import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-0 flex-1 flex flex-col overflow-y-auto" }
const _hoisted_2 = { class: "min-h-0 flex-1 flex flex-col overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactAdd = _resolveComponent("ContactAdd")!
  const _component_DynamicForm = _resolveComponent("DynamicForm")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ContactAdd, {
        onContactAdded: _ctx.contactAdded,
        onContactCancelled: _ctx.contactCancelled
      }, null, 8, ["onContactAdded", "onContactCancelled"])
    ], 512), [
      [_vShow, _ctx.showAddContact]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_ctx.section && _ctx.contacts.length)
        ? _withDirectives((_openBlock(), _createBlock(_component_DynamicForm, {
            key: 0,
            schema: _ctx.sectionSchema,
            onOnClose: _ctx.onClose,
            onOnSubmit: _ctx.onSubmit,
            onContactAdd: _ctx.contactAdd
          }, null, 8, ["schema", "onOnClose", "onOnSubmit", "onContactAdd"])), [
            [_vShow, !_ctx.showAddContact]
          ])
        : (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 1,
            message: "Loading Section"
          }))
    ], 512), [
      [_vShow, !_ctx.showAddContact]
    ])
  ], 64))
}