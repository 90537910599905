import firebase from "../firebase";
import useAuth from "../data/useAuth";

const db = firebase.firestore();
const { getHubSpotUser, hubspotUser } = useAuth();

export const bootstrapData = async (user: any): Promise<void> => {
    getHubSpotUser(user.user?.email).then(async () => {

        // Set the user document
        await db.collection("users").doc(user.user?.uid).set(
            {
                firstName: hubspotUser.firstName,
                lastName: hubspotUser.lastName,
                companyId: hubspotUser.companyId,
                companyName: hubspotUser.companyName
            }
        );
    })
  }