import { reactive } from "vue";
import router from "../router";
import firebase from "../firebase";
const auth = firebase.auth();

const hubspotUser = reactive({
  firstName: "",
  lastName: "",
  companyId: "",
  companyName: "",
  recognisedCentre: false,
  recognisedEpaProvider: false,
  onboardingCentre: false,
  onboardingEpa: false
});

export default function useAuth() {

  const getHubSpotUser = async (email: any) => {
    try {
      // get contact
      const getContactByEmail = firebase.functions("europe-west3").httpsCallable("contacts-getContactByEmail");    
      await getContactByEmail({email: email}).then((res) => {
        
          if(res.data.id === undefined) signOutUser();
          else {
            hubspotUser.firstName = res.data.properties.firstname;
            hubspotUser.lastName = res.data.properties.lastname;
            hubspotUser.companyId = res.data.associations.companies.results[0].id;
          }
        })

      // get company
      const getCompany = firebase.functions("europe-west3").httpsCallable("companies-getCompany");    
      await getCompany({companyId: hubspotUser.companyId}).then((res) => {

        hubspotUser.companyName = res.data.properties.name;
        
        if(res.data.properties.company_type.includes("Prospective Centre") || res.data.properties.company_type.includes("Recognised Centre")) {
          hubspotUser.onboardingCentre = true;
        }

        if(res.data.properties.company_type.includes("Prospective EPA Provider") || res.data.properties.company_type.includes("Recognised EPA Provider")) {
          hubspotUser.onboardingEpa = true;
        }

        // TODO: Check value exists
        // if (res.data.properties.company_type.includes("Recognised Centre")) {
        //   hubspotUser.recognisedCentre = true;
        // }
        // if (res.data.properties.company_type.includes("Recognised EPA Provider")) {
        //   hubspotUser.recognisedEpaProvider = true;
        // }

      })

      // get deals
      const getDeals = firebase.functions("europe-west3").httpsCallable("deals-getDeals");
      await getDeals({companyId: hubspotUser.companyId}).then((res) => {
        res.data.forEach((deal: any) => {
          if (deal.properties.pipeline === "12086044" && deal.properties.closedate !== null) {
            hubspotUser.onboardingCentre = true;
          }
          if (deal.properties.pipeline === "7227878") {
            hubspotUser.onboardingCentre = true;
          }
          if (deal.properties.pipeline === "7227766" && deal.properties.closedate !== null) {
            hubspotUser.onboardingEpa = true;
          }
          if (deal.properties.pipeline === "12086032") {
            hubspotUser.onboardingEpa = true;
          }
        });
      })
    }
    catch(err) {
      console.log(err)
      throw new Error(`Failed to load user data for ${auth.currentUser?.email}`);
    }
    // console.log(hubspotUser);
  }

  const signInUser = (email: string, password: string) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        router.replace({ name: "Home" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signOutUser = () => {
    auth
      .signOut()
      .then(() => {
        router.push({ name: "SignIn" });
        if(hubspotUser.companyId == "") location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { hubspotUser, getHubSpotUser, signInUser, signOutUser };
}
