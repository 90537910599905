
  import { defineComponent, ref, watchEffect } from "vue";

  export default defineComponent({
    name: "SelectInput",
    props: {
      value: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: ""
      },
      amplification: {
        type: String,
        default: ""
      },
      modelValue: {
        type: String,
        default: ""
      },
      options: {
        type: Array,
        default: () => []
      },
      required: {
        type: Boolean,
      },
      error: {
        type: String,
        default: ""
      },
    },
    emits: ["update:modelValue", "input"],
    setup(props) {
		const checked = ref(props.modelValue as unknown);
    const showAmplification = ref(false);

		watchEffect(() => {
			checked.value = props.modelValue || false;
		});

		return { checked, showAmplification};
	}
  });
