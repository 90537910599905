
import { defineComponent } from "vue";
import router from '@/router';

export default defineComponent({
  name: "AdminLayout",
  setup() {
    return { router };
  },
});
