
import { defineComponent, onBeforeMount, ref, computed } from "vue";
import DynamicForm from "@/components/DynamicForm.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import * as yup from "yup";
import useForms from "@/data/useForms";
import router from "@/router";
import firebase from "../firebase";

export default defineComponent({
    name: "ContactEdit",  
    components: {
        DynamicForm,
        LoadingSpinner
    },
    emits: ["reload"],
    setup(props, context) {
        const { getContact, editContact } = useForms();
        const auth = firebase.auth();

        const contact = ref();

        const contactSchema = computed(() => {
            return {
                title: "Edit Contact",
                submitText: "Update Contact",
                fields: [
                    {
                        name: "first_name",
                        label: "First Name",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        value: contact.value.properties.firstname,
                        validation: yup.string().required()
                    },
                    {
                        name: "last_name",
                        label: "Last Name",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        value: contact.value.properties.lastname,
                        validation: yup.string().required()
                    },
                    {
                        name: "email",
                        label: "Email",
                        component: "TextInput",
                        type: "text",
                        readonly: contact.value.properties.email === auth.currentUser?.email ? true : false,
                        required: true,
                        value: contact.value.properties.email,
                        validation: yup.string().required().email()
                    },
                    {
                        name: "phone",
                        label: "Phone",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        value: contact.value.properties.phone,
                        validation: yup.string().required()
                    }
                ]
            }
        })

        onBeforeMount(async () => {
            getContact(router.currentRoute.value.params.id)
            .then((res) => {
                contact.value = res;
            }).catch((err) => {
                console.log(err);
            });
        });

        const onSubmit = async (formValues: any) => {
            const contactObj = {
                contactId: router.currentRoute.value.params.id,
                firstName: formValues.value.first_name,
                lastName: formValues.value.last_name,
                email: formValues.value.email,
                phone: formValues.value.phone,
            }
            editContact(contactObj).then(() => {
                router.push("/contacts");
            })
        }

        const onClose = () => {
            router.go(-1);
        }

        return { contactSchema, onSubmit, onClose, contact };
    },
});
