
import { defineComponent } from "vue";
import NavMenu from "@/components/NavMenu.vue";
import UserMenu from "@/components/UserMenu.vue";

export default defineComponent({
  name: "TopBar",
  components: {
    NavMenu,
    UserMenu,
  },
});
