import { reactive } from "vue";
import firebase from "../firebase";
import useAuth from "../data/useAuth";
const db = firebase.firestore();

export default function useForms() {

  const { hubspotUser } = useAuth();
  const formsRef = db.collection("companies").doc(hubspotUser.companyId).collection("forms");

  const getForms = async (companyId: string) => {
    const formsRef = db.collection("companies").doc(companyId).collection("forms");

    const forms: Array<any> = []
    await formsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        forms.push({id: doc.id, data: doc.data()});
      });
    })
    return forms;
  }

  const getForm = async (companyId: string, formId: string) => {
    const formsRef = db.collection("companies").doc(companyId).collection("forms");

    const form: any = {
      id: "",
      data: {},
      sections: []
    };
    await formsRef.doc(formId).get().then(async (doc) => {
      if (doc.exists) {
        form.id = doc.id;
        form.data = doc.data();
        
        await formsRef.doc(formId).collection("sections").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            form.sections.push({id: doc.id, data: doc.data()});
          });
        });
      }
      else {
        throw new Error(`Cannot locate data for form with ID: ${formId}`)
      }
    });

    return form;
  }

  // TODO - Realtime updates
  
  // const getFormRealtime = async (companyId: string, formId: string) => {
  //   const formsRef = db.collection("companies").doc(companyId).collection("forms");

  //   const form: any = reactive({
  //     id: "",
  //     data: {},
  //     sections: []
  //   });

  //   formsRef.doc(formId).onSnapshot((doc) => {
  //     if (doc.exists) {
  //       form.id = doc.id;
  //       form.data = doc.data();
  //       form.sections = [];

  //       formsRef.doc(formId).collection("sections").onSnapshot((docs) => {
  //         docs.forEach((doc) => {
  //           form.sections.push({ id: doc.id, data: doc.data() });
  //         });
  //       });
  //     }
  //     else {
  //       throw new Error(`Cannot locate data for form with ID: ${formId}`);
  //     }
  //   });

  //   console.log(form);
  //   return form;
  // }

  const getFormSection = async (companyId: string, formId: string, sectionId: string) => {
    const formsRef = db.collection("companies").doc(companyId).collection("forms");

    const section: any = {
      id: "",
      data: {},
    };
    await formsRef.doc(formId).collection("sections").doc(sectionId).get().then(doc => {
      if (doc.exists) {
          section.id = doc.id;
          section.data = doc.data();
      }
    });
    return section;
  }

  const updateFormSection = async (formId: string, sectionId: string, sectionData: any) => {
    return await formsRef.doc(formId).collection("sections").doc(sectionId).update(sectionData);
  }

  const getContact = async (id: any) => {
    let contact;
    try {
      const getContact = firebase.functions("europe-west3").httpsCallable("contacts-getContact");    
      await getContact({contactId: id, companyId: hubspotUser.companyId}).then((res) => {
        contact = res.data;
      });
      return contact;
    }
    catch (err) {
      return err.message;
    }
}

  const getContacts = async () => {
    let contacts: Array<any> = []
    try {
      const getContacts = firebase.functions("europe-west3").httpsCallable("contacts-getContacts");
      await getContacts({companyId: hubspotUser.companyId}).then((res) => {
        contacts = res.data;
      });
      return contacts;
    }
    catch (err) {
      return err.message;
    }
  }

  const editContact = async (data: any) => {
    try {
      const editContact = firebase.functions("europe-west3").httpsCallable("contacts-editContact");    
      await editContact(data);
      return "editContact success"
    }
    catch (err) {
      return err.message;
    }
}

  const addContact = async (data: any) => {
      try {
        const addContact = firebase.functions("europe-west3").httpsCallable("contacts-addContact");    
        await addContact(data);
        return "addContact success"
      }
      catch (err) {
        return err.message;
      }
  }

  return { getForms, getForm, getFormSection, updateFormSection, getContact, getContacts, editContact, addContact };
}
