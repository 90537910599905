
import { defineComponent, computed } from "vue";
import DynamicForm from "@/components/DynamicForm.vue";
import * as yup from "yup";
import useAuth from "@/data/useAuth";
import useForms from "@/data/useForms";
import router from "@/router";

export default defineComponent({
    name: "ContactAdd",  
    components: {
        DynamicForm
    },
    emits: ["contactAdded", "contactCancelled"],
    setup(props, context) {
        const { hubspotUser } = useAuth();
        const { addContact } = useForms();

        const contactSchema = computed(() => {
            return {
                title: "Add Contact",
                submitText: "Add Contact",
                fields: [
                    {
                        name: "first_name",
                        label: "First Name",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        validation: yup.string().required()
                    },
                    {
                        name: "last_name",
                        label: "Last Name",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        validation: yup.string().required()
                    },
                    {
                        name: "email",
                        label: "Email",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        validation: yup.string().required().email()
                    },
                    {
                        name: "phone",
                        label: "Phone",
                        component: "TextInput",
                        type: "text",
                        required: true,
                        validation: yup.string().required()
                    }
                ]
            }
        });

        const onSubmit = async (formValues: any) => {
            const contactObj = {
                companyId: hubspotUser.companyId,
                firstName: formValues.value.first_name,
                lastName: formValues.value.last_name,
                email: formValues.value.email,
                phone: formValues.value.phone,
            }
            await addContact(contactObj).then(() => {
                context.emit("contactAdded", contactObj.email);
                if (router.currentRoute.value.name === "Contact Add") {
                    router.go(-1);
                }
                formValues.value = {};
            })
        }

        const onClose = () => {
            context.emit("contactCancelled");
            if (router.currentRoute.value.name === "Contact Add") {
                router.go(-1);
            }
        }

        return { router, contactSchema, onSubmit, onClose };
    },
});
