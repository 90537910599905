
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    name: "TextInput",
    props: {
      name: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: ""
      },
      amplification: {
        type: String,
        default: ""
      },
      modelValue: {
        type: String,
        default: ""
      },
      readonly: {
        type: Boolean,
      },
      required: {
        type: Boolean,
      },
      error: {
        type: String,
        default: ""
      },
    },
    emits: ["update:modelValue", "input", "change", "blur"],
    setup() {
      const showAmplification = ref(false);
      return { showAmplification }
    }
  });
