
import { defineComponent, ref } from "vue";
import router from '@/router';
import useForms from "@/data/useForms"
import useAuth from "@/data/useAuth"
import StatusBadge from '@/components/StatusBadge.vue';

export default defineComponent({
  name: "Form",
  components: {
    StatusBadge
  },
  setup() {
    const { getForm } = useForms();
    const { hubspotUser } = useAuth();
    const companyId: string = hubspotUser.companyId;
    const formId: string = router.currentRoute.value.params.formId as string;

    const onRowSelection = (section: string) => {
      router.push({ path: "/forms/" + router.currentRoute.value.params.formId[0] + "/" + section })
    }

    const form = ref();

    const getFormData = () => {
      getForm(companyId, formId).then((res) => {
        form.value = res;
      })
    }

    getFormData();

    router.afterEach((to, from) => {
      if (from.name === "Section Detail") {
        getFormData();
      }
    })

    return { onRowSelection, router, form, getFormData };
  },
});
