
import { computed, defineComponent, ref } from "vue";
import useAuth from "@/data/useAuth";

export default defineComponent({
  name: "UserMenu",
  components: {},
  setup() {
    const { hubspotUser, signOutUser } = useAuth();
    const user = hubspotUser;

    const getAvatar = computed((): string => {
      if (user.firstName && user.lastName) {
        return user.firstName.charAt(0) + user.lastName.charAt(0);
      } else {
        return user.companyName.charAt(0);
      }
    })

    const menuVisible = ref(false);

    const toggleMenu = () => {
      menuVisible.value = !menuVisible.value;
    };
    const hideMenu = () => {
      menuVisible.value = false;
    };

    return { user, getAvatar, toggleMenu, hideMenu, menuVisible, signOutUser };
  },
});
