
import { defineComponent, ref, computed } from "vue";
import router from '@/router';
import useForms from "../data/useForms";

export default defineComponent({
  name: "ContactsListing",
  setup() {
    const onRowSelection = (path: string) => {
      router.push({ path: "/contacts/" + path })
    }

    const { getContacts } = useForms();

    const contacts = ref([]);

    const getContactsData = () => {
      getContacts().then((res) => {
        contacts.value = res;
      })
    }

    getContactsData();

    const formatContactType = (contactType: string): string => {
      if (contactType) {
        return contactType.replaceAll(";", ", ");
      }
      else return "";
    };

    router.afterEach((to, from) => {
      if (from.name === "Contact Add" || from.name === "Contact Edit") {
        getContactsData();
      }
    })

    return { contacts, router, onRowSelection, formatContactType};
  },
});
