import * as yup from "yup";

export const getEpaStandardSelectionSchema = (section: any, contacts: any) => { 

    const standards = [
        { title: "Adult Care Worker", value: "Adult Care Worker" },
        { title: "Advance and Creative Hair Professional", value: "Advance and Creative Hair Professional" },
        { title: "Assessor/Coach", value: "Assessor/Coach" },
        { title: "Associate Project Manager", value: "Associate Project Manager" },
        { title: "Business Administrator", value: "Business Administrator" },
        { title: "Children, Young People & Families Manager", value: "Children, Young People & Families Manager" },
        { title: "Children, Young People & Families Practitioner", value: "Children, Young People & Families Practitioner" },
        { title: "Customer Service Practitioner", value: "Customer Service Practitioner" },
        { title: "Customer Service Specialist", value: "Customer Service Specialist" },
        { title: "Digital Marketer", value: "Digital Marketer" },
        { title: "Early Years Educator", value: "Early Years Educator" },
        { title: "Early Years Practitioner", value: "Early Years Practitioner" },
        { title: "Early Years Lead Practitioner", value: "Early Years Lead Practitioner" },
        { title: "Hair Professional", value: "air Professional" },
        { title: "Healthcare Assistant Practitioner", value: "Healthcare Assistant Practitioner" },
        { title: "Healthcare Cleaning Operative", value: "Healthcare Cleaning Operative" },
        { title: "Healthcare Sector", value: "Healthcare Sector" },
        { title: "Healthcare Support Worker", value: "Healthcare Support Worker" },
        { title: "HR Consultant/Partner", value: "HR Consultant/Partner" },
        { title: "HR Sector", value: "HR Sector" },
        { title: "HR Support", value: "HR Support" },
        { title: "IT Technical Salesperson", value: "IT Technical Salesperson" },
        { title: "Lead Adult Care Worker", value: "Lead Adult Care Worker" },
        { title: "Lead Practitioner in Adult Care", value: "Lead Practitioner in Adult Care" },
        { title: "Leader in Adult Care", value: "Leader in Adult Care" },
        { title: "Learning and Development Consultant", value: "Learning and Development Consultant" },
        { title: "Learning and Development Practitioner", value: "Learning and Development Practitioner" },
        { title: "Learning and Skills Teacher", value: "Learning and Skills Teacher" },
        { title: "Learning Mentor", value: "Learning Mentor" },
        { title: "Operations / Departmental Manager", value: "Operations / Departmental Manager" },
        { title: "Sales Executive", value: "Sales Executive" },
        { title: "Senior Healthcare Support Worker", value: "Senior Healthcare Support Worker" },
        { title: "Teaching Assistant", value: "Teaching Assistant" },
        { title: "Team Leader / Supervisor", value: "Team Leader / Supervisor"}
    ];

    const regions = [
        { title: "Adult Care Worker", value: "Adult Care Worker" },
        { title: "England (North East)", value: "England (North East)" },
        { title: "England (North West)", value: "England (North West)" },
        { title: "England (Yorkshire and the Humber)", value: "England (Yorkshire and the Humber)" },
        { title: "England (West Midlands)", value: "England (West Midlands)" },
        { title: "England (East Midlands)", value: "England (East Midlands)" },
        { title: "England (Eastern Region)", value: "England (Eastern Region)" },
        { title: "England (South West)", value: "England (South West)" },
        { title: "England (South East)", value: "England (South East)" },
        { title: "England (London)", value: "England (London)" },
    ];

    return {
        title: section.value.data.name,
        submitText: "Save",
        fields: [
            {
                name: "ukprn_number",
                label: "Please confirm your UKPRN",
                amplification: "This is an 8-digit number allocated to providers after successful UKRLP registration.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.ukprn_number,
                validation: yup.string().required()
            },
            {
                name: "main_provider_confirmation",
                label: "Please confirm that you are the main provider",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.main_provider_confirmation,
                validation: yup.string().required()
            },
            {
                name: "standards",
                label: "Please select the standards you wish to include as part of your application",
                component: "SelectInput",
                options: standards,
                type: "text",
                required: true,
                value: section.value.data.standards ? section.value.data.standards : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "first_anticipated_gateway_dates",
                label: "First Anticipated Gateway Dates",
                component: "TextInput",
                type: "date",
                required: true,
                value: section.value.data.first_anticipated_gateway_dates,
                validation: yup.date().required()
            },
            {
                name: "average_yearly_apprentices",
                label: "How many apprentices do you expect do you expect to submit to gateway, within an average year?",
                component: "TextInput",
                type: "number",
                required: true,
                value: section.value.data.average_yearly_apprentices,
                validation: yup.string().required()
            },
            {
                name: "epa_regions",
                label: "Please confirm the regions with face to face End-Point Assessment requirements. If you have only remote assessment requirements, please enter N/A",
                component: "SelectInput",
                options: regions,
                type: "text",
                required: true,
                value: section.value.data.epa_regions ? section.value.data.epa_regions : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "standards_with_specialisms",
                label: "If applying for standards with specialisms, please confirm the range provided. If applying for standards without a specialism, please enter N/A",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.standards_with_specialisms,
                validation: yup.string().required()
            },
            {
                name: "standards_with_teaching_subject_requirements",
                label: "If applying for standards with teaching subject requirements, please confirm the range required. If applying for standards without teaching subject requirements, please enter N/A",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.standards_with_teaching_subject_requirements,
                validation: yup.string().required()
            },
            {
                name: "complete_truthful_accurate_confirmation",
                label: "I confirm that the information provided is complete, truthful and accurate",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.complete_truthful_accurate_confirmation,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "update_changes_confirmation",
                label: "I confirm that we will update TQUK if there are any changes to the information above",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.update_changes_confirmation,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "register_apprentices_in_advance_confirmation",
                label: "I confirm that we will register all apprentices 6 months in advance of their expected gateway date",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.register_apprentices_in_advance_confirmation,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "maintain_accuracy_of_expected_dates_confirmation",
                label: "I confirm that we will maintain the accuracy of all expected gateway dates for our apprentices",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.maintain_accuracy_of_expected_dates_confirmation,
                validation: yup.boolean().required().isTrue()
            },
        ]
    }
}