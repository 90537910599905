import * as yup from "yup";

export const getOnboardingAwardingSchema = (section: any, contacts: any) => { 
    return {
        title: section.value.data.name,
        submitText: "Save",
        fields: [
            {
                name: "bd_contact",
                label: "Please provide details for a Business Development contact",
                amplification: "This person will communicate with your named TQUK Business Development Executive, who will support your organisation to maximise opportunities in Qualifications and End-Point Assessment. If you do not have a direct number, please provide a general number.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.bd_contact ? section.value.data.bd_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "bd_additional",
                label: "Please detail any additional information that may be relevant for our Business Development Team",
                amplification: "This could be your working hour or preferred contact preferences.",
                component: "TextInput",
                type: "text",
                value: section.value.data.bd_additional || "",
                validation: yup.string()
            },
            {
                name: "hoc_contact",
                label: "Please provide details for a Head of Centre/Provider or Principal",
                amplification: "This person will have overall responsibility for the centre.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.hoc_contact ? section.value.data.hoc_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "quality_contact",
                label: "Please provide details for a Quality Contact",
                amplification: "This person will communicate with the TQUK Quality Team and your named EQA, and also be sent key announcements via email about quality processes and regulations.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.quality_contact ? section.value.data.quality_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "e_certificates",
                label: "Do you wish to opt-in to e-certificates?",
                amplification: "99% of our e-certificates are issued within 24 hours. For more information, please visit https://www.tquk.org/innovation/",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.e_certificates,
                validation: yup.string().required()
            },
            {
                name: "verve_api",
                label: "Are you interested in connecting your training provider platform/e-portfolio to Verve via API?",
                amplification: "For more information, please visit https://www.tquk.org/innovation/",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.verve_api,
                validation: yup.string().required()
            },
            {
                name: "certification_contact",
                label: "Please provide details for an Exams/Administration/Certification contact",
                amplification: "This person will communicate with TQUK regarding examinations, Reg/Cert systems and receiving certificates, and also be sent key announcements via email about those items.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.certification_contact ? section.value.data.certification_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "certification_address_street",
                label: "Please provide a Certification address - Street",
                amplification: "This is the address where certificates will be posted to. E-certificates will be issued to the Exams/Administration/Certification Contact.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.certification_address_street,
                validation: yup.string().required()
            },
            {
                name: "certification_address_city",
                label: "Please provide a Certification address - City",
                amplification: "This is the address where certificates will be posted to. E-certificates will be issued to the Exams/Administration/Certification Contact.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.certification_address_city,
                validation: yup.string().required()
            },
            {
                name: "certification_address_state_region",
                label: "Please provide a Certification address - State/Region",
                amplification: "This is the address where certificates will be posted to. E-certificates will be issued to the Exams/Administration/Certification Contact.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.certification_address_state_region,
                validation: yup.string().required()
            },
            {
                name: "certification_address_postcode",
                label: "Please provide a Certification address - Postcode",
                amplification: "This is the address where certificates will be posted to. E-certificates will be issued to the Exams/Administration/Certification Contact.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.certification_address_postcode,
                validation: yup.string().required()
            },
            {
                name: "certification_address_country_region",
                label: "Please provide a Certification address Country/Region",
                amplification: "This is the address where certificates will be posted to. E-certificates will be issued to the Exams/Administration/Certification Contact.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.certification_address_country_region,
                validation: yup.string().required()
            },
            {
                name: "shipping_contact",
                label: "Please provide details for a shipping contact.",
                amplification: "This covers the dispatch of products other than certificates, such as Manuals and Assessment Records? For more information, please visit https://www.tquk.org/resources/",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.shipping_contact ? section.value.data.shipping_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "shipping_address_street",
                label: "Please provide a shipping address - Street",
                amplification: "This covers the dispatch of products other than certificates, such as Manuals and Assessment Records? For more information, please visit https://www.tquk.org/resources/",
                component: "TextInput",
                type: "text",
                value: section.value.data.shipping_address_street || "",
                validation: yup.string()
            },
            {
                name: "shipping_address_city",
                label: "Please provide a shipping address - City",
                amplification: "This covers the dispatch of products other than certificates, such as Manuals and Assessment Records? For more information, please visit https://www.tquk.org/resources/",
                component: "TextInput",
                type: "text",
                value: section.value.data.shipping_address_city || "",
                validation: yup.string()
            },
            {
                name: "shipping_address_state_region",
                label: "Please provide a shipping address - State/Region",
                amplification: "This covers the dispatch of products other than certificates, such as Manuals and Assessment Records? For more information, please visit https://www.tquk.org/resources/",
                component: "TextInput",
                type: "text",
                value: section.value.data.shipping_address_state_region || "",
                validation: yup.string()
            },
            {
                name: "shipping_address_postcode",
                label: "Please provide a shipping address - Postcode",
                amplification: "This covers the dispatch of products other than certificates, such as Manuals and Assessment Records? For more information, please visit https://www.tquk.org/resources/",
                component: "TextInput",
                type: "text",
                value: section.value.data.shipping_address_postcode || "",
                validation: yup.string()
            },
            {
                name: "shipping_address_country_region",
                label: "Please provide a shipping address - Country/Region",
                amplification: "This covers the dispatch of products other than certificates, such as Manuals and Assessment Records? For more information, please visit https://www.tquk.org/resources/",
                component: "TextInput",
                type: "text",
                value: section.value.data.shipping_address_country_region || "",
                validation: yup.string()
            },
            {
                name: "operations_additional",
                label: "Please detail any additional information that may be relevant for our Operations and Customer Service Teams",
                component: "TextInput",
                type: "text",
                value: section.value.data.operations_additional || "",
                validation: yup.string()
            },
            {
                name: "finance_contact",
                label: "Please provide details for a Finance contact",
                amplification: "This person will receive all financial communication including invoices.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.finance_contact ? section.value.data.finance_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "finance_address_street",
                label: "Please provide a finance address - Street",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_street,
                validation: yup.string().required()
            },
            {
                name: "finance_address_city",
                label: "Please provide a finance address - City",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_city,
                validation: yup.string().required()
            },
            {
                name: "finance_address_state_region",
                label: "Please provide a finance address - State/Region",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_state_region,
                validation: yup.string().required()
            },
            {
                name: "finance_address_postcode",
                label: "Please provide a finance address - Postcode",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_postcode,
                validation: yup.string().required()
            },
            {
                name: "finance_address_country_region",
                label: "Please provide a finance address - Country/Region",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_country_region,
                validation: yup.string().required()
            },
            {
                name: "purchase_order_system",
                label: "Do you operate with a purchase order system?",
                amplification: "TQUK provides you with an opportunity to provide a purchase order number when using our systems.",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.purchase_order_system,
                validation: yup.string().required()
            },
            {
                name: "require_supplier_form",
                label: "Is TQUK required to complete a form or process to be setup as a supplier?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.require_supplier_form,
                validation: yup.string().required()
            },
            {
                name: "supplier_form_link",
                label: "Please attach or link to a supplier form",
                component: "FileInput",
                required: true,
                value: section.value.data.supplier_form_link || "",
                validation: yup.string().when("require_supplier_form", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "vat_exemption",
                label: "Do you have a VAT exemption under VAT notice Education and vocational training (VAT Notice 701/30) that can be evidenced with a VAT exemption certificate?",
                amplification: "More details can be found here: https://www.gov.uk/guidance/vat-on-education-and-vocational-training-notice-70130",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.vat_exemption,
                validation: yup.string().required()
            },
            {
                name: "vat_exemption_link",
                label: "Please attach or link your VAT exemption certificate",
                component: "FileInput",
                required: true,
                value: section.value.data.vat_exemption_link || "",
                validation: yup.string().when("vat_exemption", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "vat_additional",
                label: "Please summarise any additional information regarding the VAT status of your organisation",
                component: "TextInput",
                type: "text",
                value: section.value.data.vat_additional || "",
                validation: yup.string()
            },
            {
                name: "finance_additional",
                label: "Please detail any additional information that may be relevant for our Finance Team",
                component: "TextInput",
                type: "text",
                value: section.value.data.finance_additional || "",
                validation: yup.string()
            },
            {
                name: "declaration",
                label: "I consent that the information provided is accurate and I have authorisation to submit on behalf of the organisation",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.declaration,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "confirmation",
                label: "I consent that I have a lawful basis for the sharing of any personal data, relating to named individuals, including but not limited to consent",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.confirmation,
                validation: yup.boolean().required().isTrue()
            },
        ]
    }
}