import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative h-full"
}
const _hoisted_2 = { class: "w-10 h-10 rounded-full bg-white text-brand font-medium mr-4 flex items-center justify-center" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "text-white font-medium" }
const _hoisted_5 = { class: "text-white text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "bg-white border rounded w-full py-2 shadow absolute top-100 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "flex h-full items-center cursor-pointer p-4",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["stop"]))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getAvatar), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.user.firstName) + " " + _toDisplayString(_ctx.user.lastName), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.user.companyName), 1)
          ])
        ])), [
          [_directive_click_outside, _ctx.hideMenu]
        ]),
        (_ctx.menuVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("a", {
                class: "cursor-pointer hover:bg-gray-200 w-full block px-4 py-2 text-center",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.signOutUser && _ctx.signOutUser(...args)))
              }, "Sign Out")
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}