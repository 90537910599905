
import { defineComponent } from "vue";
import router from "../router";
import firebase from "../firebase";

export default defineComponent({
  name: "EmailActionHandler",
  setup() {
    const auth = firebase.auth();
    const params = router.currentRoute.value.query;
    const mode = params.mode?.toString();
    const oobCode = params.oobCode?.toString();
    const apiKey = params.apiKey?.toString();

    // Reset password logic
    const handleResetPassword = () => {
      if (oobCode) {
        // Redirect to firebase UI
        const redirect = `https://tquk-form-system-prod.firebaseapp.com/__/auth/action?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}&lang=en`
        window.location.replace(redirect);
      }
    }

    if (oobCode) {
      switch (mode) {
        case 'verifyEmail':
          console.log("verifyEmail");
          auth.applyActionCode(oobCode).then(() => {
          auth.currentUser?.getIdToken(true).then(() => {
            auth.currentUser?.reload().then(() => {
              router.replace("/");
            });
          })
        }).catch((err) => {
          console.log(err);
        });
        break;
        case 'resetPassword':
          console.log("resetPassword");
          handleResetPassword()
      }
    }

    return { params, mode};
  },
});
