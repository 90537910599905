
import { defineComponent, ref, watchEffect } from "vue";
import { Status } from "@/types"

export default defineComponent({
  name: "StatusBadge",
  props: {
     status: {
        type: Number,
        required: true
      }
  },
  setup(props) {
    const statusText = ref();
    const statusStyle = ref();

    watchEffect(() => {
      switch ( props.status ) {
        case Status.IN_PROGRESS:
            statusStyle.value = ["bg-yellow-100", "text-yellow-500", "border", "border-yellow-500"]
            statusText.value = "In Progress"
            break;
        case Status.COMPLETE:
            statusStyle.value = ["bg-green-100", "text-green-500", "border", "border-green-500"]
            statusText.value = "Complete"
            break;
        default: 
            statusStyle.value = ["bg-gray-100", "text-gray-500", "border", "border-gray-500"]
            statusText.value = "Not Started"
            break;
      }
    }) 
    return { statusStyle, statusText }
  }
});
