import * as yup from "yup";

export const getOnboardingEpaSchema = (section: any, contacts: any) => { 
    return {
        title: section.value.data.name,
        submitText: "Save",
        fields: [
            {
                name: "bd_contact",
                label: "Please provide details for a Business Development contact",
                amplification: "This person will communicate with your named TQUK Business Development Executive, who will support your organisation to maximise opportunities in Qualifications and End-Point Assessment. If you do not have a direct number, please provide a general number.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.bd_contact ? section.value.data.bd_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "bd_additional",
                label: "Please detail any additional information that may be relevant for our Business Development Team",
                amplification: "This could be your working hour or preferred contact preferences.",
                component: "TextInput",
                type: "text",
                value: section.value.data.bd_additional || "",
                validation: yup.string()
            },
            {
                name: "epa_head_of_centre_contact",
                label: "Please provide details for an EPA Head of Centre/Provider or Principal",
                amplification: "This person will have overall responsibility for the provider.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.epa_head_of_centre_contact ? section.value.data.epa_head_of_centre_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "epa_general_admin_contact",
                label: "Please provide details for an EPA General/Administration contact",
                amplification: "This person will communicate with TQUK regarding all general EQA queries, including gateway, assessment and results, and also be sent key announcements via email.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.epa_general_admin_contact ? section.value.data.epa_general_admin_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "epa_customer_service_additional",
                label: "Please detail any additional information that may be relevant for our Operations and Customer Service Teams",
                component: "TextInput",
                type: "text",
                value: section.value.data.epa_customer_service_additional || "",
                validation: yup.string()
            },
            {
                name: "epa_finance_contact",
                label: "Please provide details for an EPA Finance contact",
                amplification: "This person will receive all financial communication including invoices.",
                component: "SelectInput",
                contactadd: true,
                options: contacts.value,
                type: "text",
                required: true,
                value: section.value.data.epa_finance_contact ? section.value.data.epa_finance_contact : "0",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "finance_address_street",
                label: "Please provide a finance address - Street",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_street,
                validation: yup.string().required()
            },
            {
                name: "finance_address_city",
                label: "Please provide a finance address - City",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_city,
                validation: yup.string().required()
            },
            {
                name: "finance_address_state_region",
                label: "Please provide a finance address - State/Region",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_state_region,
                validation: yup.string().required()
            },
            {
                name: "finance_address_postcode",
                label: "Please provide a finance address - Postcode",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_postcode,
                validation: yup.string().required()
            },
            {
                name: "finance_address_country_region",
                label: "Please provide a finance address - Country/Region",
                amplification: "This address will appear on your invoices.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.finance_address_country_region,
                validation: yup.string().required()
            },
            {
                name: "purchase_order_system",
                label: "Do you operate with a purchase order system?",
                amplification: "TQUK provides you with an opportunity to provide a purchase order number when using our systems.",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.purchase_order_system,
                validation: yup.string().required()
            },
            {
                name: "require_supplier_form",
                label: "Is TQUK required to complete a form or process to be setup as a supplier?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.require_supplier_form,
                validation: yup.string().required()
            },
            {
                name: "supplier_form_link",
                label: "Please attach or link to a supplier form",
                component: "FileInput",
                required: true,
                value: section.value.data.supplier_form_link || "",
                validation: yup.string().when("require_supplier_form", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "vat_exemption",
                label: "Do you have a VAT exemption under VAT notice Education and vocational training (VAT Notice 701/30) that can be evidenced with a VAT exemption certificate?",
                amplification: "More details can be found here: https://www.gov.uk/guidance/vat-on-education-and-vocational-training-notice-70130",
                component: "RadioInput",
                options: [
                    { title: "No", value: "false" },
                    { title: "Yes", value: "true" },
                ],
                required: true,
                type: "radio",
                value: section.value.data.vat_exemption,
                validation: yup.string().required()
            },
            {
                name: "vat_exemption_link",
                label: "Please attach or link your VAT exemption certificate",
                component: "FileInput",
                required: true,
                value: section.value.data.vat_exemption_link || "",
                validation: yup.string().when("vat_exemption", {
                    is: "true",
                    then: yup.string().required()
                })
            },
            {
                name: "vat_additional",
                label: "Please summarise any additional information regarding the VAT status of your organisation",
                component: "TextInput",
                type: "text",
                value: section.value.data.vat_additional || "",
                validation: yup.string()
            },
            {
                name: "finance_additional",
                label: "Please detail any additional information that may be relevant for our Finance Team",
                component: "TextInput",
                type: "text",
                value: section.value.data.finance_additional || "",
                validation: yup.string()
            },
            {
                name: "ukprn_number",
                label: "Please confirm your UKPRN",
                amplification: "This is an 8-digit number allocated to providers after successful UKRLP registration.",
                component: "TextInput",
                type: "text",
                required: true,
                value: section.value.data.ukprn_number,
                view: "all",
                validation: yup.string().required()
            },
            {
                name: "main_provider_confirmation",
                label: "Please confirm that you are the main provider",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.main_provider_confirmation,
                view: "all",
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "declaration",
                label: "I consent that the information provided is accurate and I have authorisation to submit on behalf of the organisation",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.declaration,
                validation: yup.boolean().required().isTrue()
            },
            {
                name: "confirmation",
                label: "I consent that I have a lawful basis for the sharing of any personal data, relating to named individuals, including but not limited to consent",
                component: "CheckboxInput",
                required: true,
                value: section.value.data.confirmation,
                validation: yup.boolean().required().isTrue()
            },
        ]
    }
}