
import { defineComponent, ref, Ref, watch, reactive, computed } from "vue";
import useForms from "@/data/useForms";
import useQualifications from "@/data/useQualifications";
import TextInput from "@/components/inputs/TextInput.vue";
import SelectInput from "@/components/inputs/SelectInput.vue";
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import RadioInput from "@/components/inputs/RadioInput.vue";
import FileInput from "@/components/inputs/FileInput.vue";
import router from "@/router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ContactAdd from "@/views/ContactAdd.vue";
import useAuth from "@/data/useAuth";
import Multiselect from '@vueform/multiselect';
import * as yup from "yup";

export default defineComponent({
  name: "QualificationApproval",
  components: {
    TextInput,
    SelectInput,
    CheckboxInput,
    RadioInput,
    FileInput,
    LoadingSpinner,
    ContactAdd,
    Multiselect
  },
  emits: ["onClose", "onSubmit", "contactAdd"],
    setup(props, context) {

      const { hubspotUser } = useAuth();
      const selections = ref([]);
      const contacts: Ref<Array<any>> = ref([]);
      const { getContacts, updateFormSection } = useForms();
      const { getQualifications, qualifications, applyForQualification } = useQualifications();
      getQualifications();

      const showAmplification = ref(false);

      // Form schema
      const fields = ref([
            {
                name: "annual_learner_registrations",
                label: "Annual learner registrations",
                component: "TextInput",
                type: "number",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().required()
            },
            {
                name: "first_registration_date",
                label: "First registration date",
                component: "TextInput",
                type: "date",
                required: true,
                value: "",
                view: "all",
                validation: yup.date().required()
            },
            {
                name: "qualification_contact",
                label: "Please select a specific qualification contact",
                component: "SelectInput",
                contactadd: true,
                options: contacts,
                type: "text",
                required: true,
                value: "0",
                view: "all",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "work_with_other_awarding_organisations",
                label: "Do you work with any other Awarding Organisations?",
                component: "SelectInput",
                options: [
                    { title: "AQA", value: "AQA"},
                    { title: "Ascentis", value: "Ascentis"},
                    { title: "BIIAB", value: "BIIAB"},
                    { title: "Cambridge Assessment", value: "Cambridge Assessment"},
                    { title: "City and Guilds", value: "City and Guilds"},
                    { title: "First Aid Awards", value: "First Aid Awards"},
                    { title: "Gateway Qualifications", value: "Gateway Qualifications"},
                    { title: "Highfield", value: "Highfield"},
                    { title: "NCFE", value: "NCFE"},
                    { title: "NOCN", value: "NOCN"},
                    { title: "None", value: "None"},
                    { title: "OCR", value: "OCR"},
                    { title: "Other", value: "Other"},
                    { title: "Pearson", value: "Pearson"},
                    { title: "Qualsafe Awards", value: "Qualsafe Awards"},
                    { title: "VTCT", value: "VTCT"},
                ],
                type: "text",
                required: true,
                value: "0",
                view: "all",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "awarding_organisations_with_dcs",
                label: "Please select any awarding organisations that you have DCS with",
                component: "SelectInput",
                options: [
                    { title: "AQA", value: "AQA"},
                    { title: "Ascentis", value: "Ascentis"},
                    { title: "BIIAB", value: "BIIAB"},
                    { title: "Cambridge Assessment", value: "Cambridge Assessment"},
                    { title: "City and Guilds", value: "City and Guilds"},
                    { title: "First Aid Awards", value: "First Aid Awards"},
                    { title: "Gateway Qualifications", value: "Gateway Qualifications"},
                    { title: "Highfield", value: "Highfield"},
                    { title: "NCFE", value: "NCFE"},
                    { title: "NOCN", value: "NOCN"},
                    { title: "None", value: "None"},
                    { title: "OCR", value: "OCR"},
                    { title: "Other", value: "Other"},
                    { title: "Pearson", value: "Pearson"},
                    { title: "Qualsafe Awards", value: "Qualsafe Awards"},
                    { title: "VTCT", value: "VTCT"},
                ],
                type: "text",
                required: true,
                value: "0",
                view: "all",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "provide_qualification_with_skills_network",
                label: "Do you provide this qualification with our partner The Skills Network and their EQUAL platform or resources?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "No" },
                    { title: "Yes", value: "Yes" },
                ],
                required: true,
                type: "radio",
                value: "",
                view: "all",
                validation: yup.string().required()
            },
            {
              name: "vat_exemption_through_funding",
              label: "Do you offer this qualification under an approved government funding scheme, and are therefore eligible for VAT exemption to the extent to which it is funded?",
              amplification: "Examples of government funding schemes are vocational training schemes approved by the ESFA, DfE, DWP e.g. Adult Education Budget",
              component: "RadioInput",
                options: [
                    { title: "No", value: "No" },
                    { title: "Yes", value: "Yes" },
                ],
                required: true,
                type: "radio",
                value: "",
                view: "all",
                validation: yup.string().required()
            },
            {
                name: "approved_government_funding_schemes",
                label: "Please specify the approved government funding scheme(s)",
                component: "TextInput",
                type: "text",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().when("vat_exemption_through_funding", {
                    is: "Yes",
                    then: yup.string().required()
                })
            },
            {
                name: "intend_to_deliver_qualification_outside_uk",
                label: "Do you intend to deliver this qualification outside of the UK?",
                component: "RadioInput",
                options: [
                    { title: "No", value: "No" },
                    { title: "Yes", value: "Yes" },
                ],
                required: true,
                type: "radio",
                value: "",
                view: "all",
                validation: yup.string().required()
            },
            {
                name: "qualification_delivery_methods",
                label: "Please confirm the delivery methods you will use for the qualification",
                component: "SelectInput",
                options: [
                    { title: "Online", value: "online" },
                    { title: "Classroom", value: "classroom" },
                    { title: "Blended", value: "blended" },
                ],
                type: "text",
                required: true,
                value: "0",
                view: "all",
                validation: yup.string().required().not(["0"], "This is a required field")
            },
            {
                name: "iqa_strategy_document",
                label: "Please attach your IQA Policy/Strategy document",
                component: "FileInput",
                type: "text",
                required: true,
                value: "",
                view: "all",
                validation: yup.string().required()
            }
        ]);

      // declaration form schema
      const declarationSchema = {
        name: "declaration",
        label: "Declaration? Accurate, In line with Centre Agreement",
        component: "CheckboxInput",
        required: true,
        validation: yup.boolean().required().isTrue()
      }

      // Get contacts from API
      const getContactsData = () => {
          contacts.value = [];
          getContacts().then((res) => {
              res.forEach((contact: any) => {
                  contacts.value.push({title: `${contact.properties.firstname} ${contact.properties.lastname}`, value: contact.id})
              });
          });
      }
      getContactsData();

      // Return qualification record by id
      const getQualification = (product_id: string): any => {
        const selected = qualifications.value.filter((qualification: any) => {
          return qualification.product_id === product_id;
        });
        return selected[0];
      };

      // Add contact logic
      const showAddContact = ref(false);
      const contactAdd = () => {
            showAddContact.value = true;
        }
        const contactCancelled = () => {
            showAddContact.value = false;
        }
        const contactAdded = (email: any) => {
            getContactsData();
            showAddContact.value = false;
        }

      watch(selections, () => {
        selections.value.forEach(selection => {
          formData[selection] = {values: {}, errors: {}};
        });
      })

      // Validation schema
      let validationSchema: any = reactive({});
      fields.value.forEach((field: any) => {
        validationSchema[field.name] = field.validation;
      });
      const formValidation = yup.object().shape(validationSchema);

      // Set all selections key/value for question
      const setAll = (field: any, val: any) => {
        // create a key value pair for field/value in every selections object

        console.log("setAll", field, val);
        
        selections.value.forEach(selection => {
          formData[selection].values[field] = val;

          console.log("validateAt", field, val);
        
          formValidation.validateAt(field, formData[selection].values)
            .then(() => {
              delete formData[selection].errors[field];
              delete formErrors[field];
            })
            .catch((err: any) => {
              formData[selection].errors[field] = err.message;
              formErrors[field] = err.message;
            })
            .finally(isFormValid);
          });

      }

      // Set a single selections key/value for question
      const setOne = (selection: any, field: any, val: any) => {
        // create a key value pair for the field/value in the selection object

        console.log("setOne", selection, field, val);

        formData[selection].values[field] = val;
        console.log("validateAt", field, val);

        formValidation.validateAt(field, formData[selection].values)
          .then(() => {
            delete formData[selection].errors[field];
            delete formErrors[field];
          })
          .catch((err: any) => {
              formData[selection].errors[field] = err.message;
              formErrors[field] = err.message;
          })
          .finally(isFormValid);
      }

      // Fires when input method changes
      const onChangeInputMethod = (field: string, value: any) => {
        selections.value.forEach(selection => {
          delete formData[selection].values[field];
          delete formData[selection].errors[field];
          delete formErrors[field];
          delete formValues[field];
          delete formValues[selection + field];
        });
        isFormValid();
      }

      const formValid: Ref<boolean> = ref(false);
      let formData: any = reactive({});
      const formValues: any = reactive({
        qualification_contact: "0",
        work_with_other_awarding_organisations: "0",
        qualification_delivery_methods: "0",
        awarding_organisations_with_dcs: "0"
      });
      const formErrors: any = reactive({});

      const isFormValid = () => {
        selections.value.forEach(selection => {
          formValidation.validate(formData[selection].values, { abortEarly: true })
            .then(() => {
              formValid.value = true;
            })
            .catch((err: any) => {
              formValid.value = false;
            });
        });
      }

      const errorCount = computed(() => {
        let count = 0;
        selections.value.forEach((selection: any) => {
          count += Object.keys(formData[selection].errors).length;
        });
        return count;
      })

      const onClose = async () => {
        router.push(`/forms/1`);
      }

      const onSubmit = () => {
        selections.value.forEach(selection => {
          let data: any = reactive({});
          data.id = getQualification(selection).product_id;
          data.title = getQualification(selection).custom_title;
          data.fields = formData[selection].values;
        
          applyForQualification(hubspotUser.companyId, data);
        });
        updateFormSection("1", "3", {status: 2, progress: 100}).then(() => {
            router.push(`/forms/1`).then(() => {
              setTimeout(function () { 
                router.go(0);
              }, 3000);
            });
        });
      }

      return { router, qualifications, selections, fields, declarationSchema, contacts, contactAdd, contactCancelled, contactAdded, showAddContact, onClose, onSubmit, getQualification, setOne, setAll, onChangeInputMethod, formData, formValues, formErrors, errorCount, formValid, showAmplification };
    }
});

