
import { defineComponent, computed, ref } from "vue";
import router from '@/router';
import Modal from '@/components/Modal.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import useAuth from "../data/useAuth";
import useForms from "@/data/useForms"

export default defineComponent({
  name: "FormsListing",
  components: {
    StatusBadge
  },
  setup() {
    const { hubspotUser } = useAuth();
    const { getForms } = useForms();

    const tabs = [
      { name: "All", path: "/forms/all", current: true },
      { name: "In Progress", path: "/forms/in-progress", current: false },
      { name: "Complete", path: "/forms/complete", current: false },
    ]

    const visibleForms: any = {
      1: hubspotUser.onboardingCentre || hubspotUser.recognisedCentre,
      2: hubspotUser.onboardingEpa || hubspotUser.recognisedEpaProvider
    }

    const filteredForms = computed(() => {
      if (router.currentRoute.value.meta.status) {
        return forms.value.filter((form: any) => {
          return form.data.status === router.currentRoute.value.meta.status && visibleForms[form.id] === true;
        })
      }
      else {
        return forms.value.filter((form: any) => {
          return visibleForms[form.id] === true;
        })
      }
    })

    const onChangeTab = (path: string) => {
      router.push({ path: path })
        tabs.forEach(tab => {
          tab.current = false;
          if (tab.path === path) {
            tab.current = true;
          }
        });
    }

    const onRowSelection = (path: string) => {
      router.push({ path: "/forms/" + path })
    }

    // const newFormModalOpen = ref(false);

    // const onClickNewForm = () => {
    //   newFormModalOpen.value = true;
    // }
    
    const forms = ref();

    getForms(hubspotUser.companyId).then((res) => {
      forms.value = res;
    })

    return { hubspotUser, tabs, filteredForms, router, onChangeTab, onRowSelection, forms };
  },
});
