
import { defineComponent } from "vue";
import FullLayout from "./layouts/FullLayout.vue";

export default defineComponent({
  name: "App",
  setup() {
    return { FullLayout };
  },
});
