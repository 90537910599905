
import { defineComponent, ref, Ref } from "vue";
import firebase from "@/firebase";
import router from "@/router";
import useCompanies from "@/data/useCompanies";
import useForms from "@/data/useForms";
import StatusBadge from '@/components/StatusBadge.vue';
import { getSectionSchema } from "@/schema"

export default defineComponent({
    name: "AdminFormDetail",  
    components: {
        StatusBadge
    },
    setup() {
        const { getCompany } = useCompanies();
        const { getForm, getFormSection } = useForms();
        const storage = firebase.storage();
        const companyId: string = router.currentRoute.value.params.companyId as string;
        const formId: string = router.currentRoute.value.params.formId as string;
        const errors: any = ref([]);
        const company = ref();
        const form = ref();

        getCompany(companyId).then((res) => {
            company.value = res;
        }).catch((err) => {
            errors.value.push(err.message);
            console.log(err);
        })

        const { getContacts } = useForms();
        const contacts: Ref<Array<any>> = ref([]);

        const sectionRef = ref();
        const sectionsSchema: Ref<any> = ref([]);

        getForm(companyId, formId).then((res) => {
            form.value = res;
            form.value.sections.forEach((section: any) => {

              getFormSection(companyId, formId, section.id)
                .then((res) => {
                    sectionRef.value = res;

                    // Get the section schema
                    let sectionSchema: any = getSectionSchema(formId, section.id, sectionRef, contacts);
                    sectionsSchema.value.push({schema: sectionSchema, document: section});

                }).catch((err) => {
                    console.log(err);
                });
            });

        }).catch((err) => {
          errors.value.push(err.message);
            console.log(err);
        })

        const getDownloadUrl = async (path: string) => {
          const fileRef = storage.ref().child(`${companyId}/${path}`);
          fileRef.getDownloadURL().then((res) => {
            const link = document.createElement("a");
            link.download = path;
            link.href = res;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);            
          });
        }

        return { router, company, form, errors, sectionsSchema, getDownloadUrl };
    },
});
