
import { defineComponent, ref, Ref } from "vue";
import DynamicForm from "@/components/DynamicForm.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ContactAdd from "@/views/ContactAdd.vue";
import useForms from "@/data/useForms";
import router from "@/router";
import useAuth from "@/data/useAuth";
import { getSectionSchema } from "../../schema";

export default defineComponent({
    name: "OnboardingAwarding",  
    components: {
        DynamicForm,
        LoadingSpinner,
        ContactAdd
    },
    setup() {
        const { getFormSection, updateFormSection } = useForms();
        const { hubspotUser } = useAuth();
        const formId = router.currentRoute.value.params.formId[0] as string;
        const sectionId = router.currentRoute.value.params.sectionId[0] as string;
        const section = ref();
        const sectionSchema = ref();
        const showAddContact = ref(false);

        // Get the section schema
        const getSchema = (section: any, contacts: any) => {
            sectionSchema.value = getSectionSchema(formId, sectionId, section, contacts);
        }

        getFormSection(hubspotUser.companyId, formId, sectionId)
            .then((res) => {
                section.value = res;

                // Get the section schema
                getSchema(section, contacts);

                // console.log("getFormSection", section.value);
            }).catch((err) => {
                console.log(err);
            });

        const onClose = async () => {
            router.push(`/forms/${formId}`);
        }

        const onSubmit = async (formValues: any) => {
            formValues.value.status = 2;
            formValues.value.progress = 100;
            updateFormSection(formId, sectionId, formValues.value).then(() => {
                router.push(`/forms/${formId}`).then(() => {
                  setTimeout(function () { 
                    router.go(0);
                  }, 3000);
                });
            });
        }

        const { getContacts } = useForms();
        const contacts: Ref<Array<any>> = ref([]);
        
        const getContactsData = () => {
            contacts.value = [];
            getContacts().then((res) => {
                res.forEach((contact: any) => {
                    contacts.value.push({title: `${contact.properties.firstname} ${contact.properties.lastname}`, value: contact.id})
                });
                getSchema(section, contacts);
            });
        }

        getContactsData();

        const contactAdd = () => {
            showAddContact.value = true;
        }

        const contactCancelled = () => {
            showAddContact.value = false;
        }

        const contactAdded = (email: any) => {
            getContactsData();
            showAddContact.value = false;
        }

        return { sectionSchema, onClose, onSubmit, section, contacts, showAddContact, contactAdd, contactCancelled, contactAdded };
    },
});
