
import { defineComponent } from "vue";
import router from "@/router"

export default defineComponent({
  name: "SlideOut",
  setup() {
    return { router }
  }
});
