
import { defineComponent, ref, watchEffect } from "vue";

  export default defineComponent({
    name: "SelectInput",
    props: {
      value: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: ""
      },
      contactadd: {
        type: Boolean,
        default: false
      },
      amplification: {
        type: String,
        default: ""
      },
      modelValue: {
        type: String,
        default: ""
      },
      options: {
        type: Array,
        default: () => []
      },
      multiple: {
        type: Boolean,
      },
      required: {
        type: Boolean,
      },
      error: {
        type: String,
        default: ""
      },
    },
    emits: ["update:modelValue", "change", "input", "blur", "contactAdd"],
    setup(props, context) {
		const selected = ref();
		const showAmplification = ref(false);

		watchEffect(() => {
			selected.value = props.modelValue;
		});

    const contactAdd = () => {
      context.emit("contactAdd");
    }

		return { selected, showAmplification, contactAdd };
	}
  });
