import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicForm = _resolveComponent("DynamicForm")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_ctx.contact)
    ? (_openBlock(), _createBlock(_component_DynamicForm, {
        key: 0,
        schema: _ctx.contactSchema,
        onOnSubmit: _ctx.onSubmit,
        onOnClose: _ctx.onClose
      }, null, 8, ["schema", "onOnSubmit", "onOnClose"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_LoadingSpinner, { message: "Loading Contact" })
      ]))
}