import { ref } from "vue";
import firebase from "../firebase";
const db = firebase.firestore();

const qualifications = ref([]);

export default function useQualifications() {

  // Get the complete list of quals
  const getQualifications = async () => {
    try {
      // get contact
      const getQualifications = firebase.functions("europe-west3").httpsCallable("qualifications-getQualifications");    
      await getQualifications().then((res) => {
        qualifications.value = res.data;
      })
    }
    catch(err) {
      console.log(err)
      throw new Error(`Failed to load qualifications data`);
    }
  }

  // Apply for qualification
  const applyForQualification = async (companyId: string, qualificationData: any) => {
    const formsRef = db.collection("companies").doc(companyId).collection("qualifications");
    await formsRef.doc().set(qualificationData);
  }

  // Get company qualification
  const getCompanyQualification = async (companyId: string, qualificationId: string) => {
    const formsRef = db.collection("companies").doc(companyId).collection("qualifications").doc(qualificationId);
    
    formsRef.get().then((doc) => {
      if(doc.exists) {
        return doc.data();
      }
    })
  }

  // Get company qualifications
  const getCompanyQualifications = async (companyId: string) => {
    const formsRef = db.collection("companies").doc(companyId).collection("qualifications")

    const qualifications: Array<any> = []
    await formsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        qualifications.push({id: doc.id, data: doc.data()});
      });
    })
    return qualifications;
  }

  return { getQualifications, qualifications, applyForQualification, getCompanyQualification, getCompanyQualifications };
}
