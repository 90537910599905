import firebase from "../firebase";
const db = firebase.firestore();

export default function useStandards() {

  // Apply for standards
  const applyForStandards = async (companyId: string, standardsData: any) => {
    const formsRef = db.collection("companies").doc(companyId).collection("standards");
    await formsRef.doc().set(standardsData);
  }

  // Get company standards
  const getCompanyStandards = async (companyId: string) => {
    const formsRef = db.collection("companies").doc(companyId).collection("standards")

    const standards: Array<any> = []
    await formsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        standards.push({id: doc.id, data: doc.data()});
      });
    })
    return standards;
  }

  return { getCompanyStandards, applyForStandards };
}
