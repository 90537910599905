
import { reactive, ref } from "vue";
import router from "../router";
import * as yup from "yup";
import firebase from "../firebase";
import useAuth from "@/data/useAuth";
import useForm from "@/use/useForm";
import { bootstrapData } from "@/data/bootstrapData";

export default {
  name: "SignIn",
  setup() {
    const params = router.currentRoute.value.query;
    const email = params.email?.toString();
    const registerError = ref("");

    if (!email) {
      router.replace({ name: "SignIn" });
    }

    const schema = reactive({
      submitText: "Register",
      fields: [
        {
          name: "Email",
          label: "Email",
          type: "email",
          readonly: true,
          value:  email,
          validation: yup.string().email().required()
        },
        {
          name: "Password",
          label: "Password",
          type: "password",
          value: "",
          validation: yup.string().required().min(8)
          .matches(/(?=.*[a-z])/, "Password must contain at least 1 lowercase alphabetical character")
          .matches(/(?=.*[A-Z])/, "Password must contain at least 1 uppercase alphabetical character")
          .matches(/(?=.*[0-9])/, "Password must contain at least 1 numeric character")
          .matches(/(?=.*[!@#$%^&*])/, "Password must contain at least one special character")
        }
      ]
    });

    const { formValid, formValues, formErrors, validateField } = useForm(schema.fields);
    const auth = firebase.auth();

    const registerUser = () => {
      auth
        .createUserWithEmailAndPassword(formValues.value.Email, formValues.value.Password)
        .then(async (user) => {
          bootstrapData(user);
          auth.currentUser?.sendEmailVerification()
            .then(function() {
              router.replace({ name: "Forms" });
            })
            .catch(function(err) {
              registerError.value = err.message;
            });
        })
        .catch((err) => {
          registerError.value = err.message;
        });
    };

    return { email, schema, registerUser, registerError, formValid, formValues, formErrors, validateField };
  },
};
