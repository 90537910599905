
import { defineComponent, ref } from "vue";
import firebase from "@/firebase";
import router from "@/router";
import useCompanies from "@/data/useCompanies";
import useForms from "@/data/useForms";
import StatusBadge from '@/components/StatusBadge.vue';
import useQualifications from "@/data/useQualifications";
import useStandards from "@/data/useStandards";

export default defineComponent({
    name: "AdminCompanyDetail",  
    components: {
        StatusBadge
    },
    setup() {
        const { getCompany } = useCompanies();
        const { getForms } = useForms();
        const { getCompanyQualifications } = useQualifications();
        const { getCompanyStandards } = useStandards();
        const storage = firebase.storage();
        const companyId: string = router.currentRoute.value.params.companyId as string;
        const errors: any = ref([]);
        const company = ref();
        const forms = ref();
        const qualifications = ref();
        const standards = ref();

        getCompany(companyId).then((res) => {
            company.value = res;
        }).catch((err) => {
            errors.value.push(err.message);
            console.log(err);
        })

        getForms(companyId).then((res) => {
            forms.value = res;
        }).catch((err) => {
            errors.value.push(err.message);
            console.log(err);
        })

        getCompanyQualifications(companyId).then((res) => {
            qualifications.value = res;
        }).catch((err) => {
            errors.value.push(err.message);
            console.log(err);
        })

        getCompanyStandards(companyId).then((res) => {
            standards.value = res;
        }).catch((err) => {
            errors.value.push(err.message);
            console.log(err);
        })

        const onRowSelection = (formId: string) => {
            router.push({ name: "Admin Form Detail", params: {companyId: companyId, formId: formId}})
        }

        const getDownloadUrl = async (path: string) => {
          const fileRef = storage.ref().child(`${companyId}/${path}`);
          fileRef.getDownloadURL().then((res) => {
            const link = document.createElement("a");
            link.download = path;
            link.href = res;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);            
          });
        }

        return { router, company, forms, qualifications, standards, errors, onRowSelection, getDownloadUrl };
    },
});
