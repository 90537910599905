import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import firebase from "../firebase";
const auth = firebase.auth();

// Layouts
import BlankLayout from "../layouts/BlankLayout.vue";
import AdminLayout from "../layouts/AdminLayout.vue";

// Views
import SignIn from "../views/SignIn.vue";
import Register from "../views/Register.vue";
import VerificationPending from "../views/VerificationPending.vue";
import EmailActionHandler from "../views/EmailActionHandler.vue";

// Contacts
import ContactsListing from "../views/ContactsListing.vue";
import ContactEdit from "../views/ContactEdit.vue";
import ContactAdd from "../views/ContactAdd.vue";

// Forms
import FormsListing from "../views/FormsListing.vue";
import FormDetail from "../views/FormDetail.vue";
import SectionDetail from "../views/forms/SectionDetail.vue";
import QualificationApproval from "../views/forms/QualificationApproval.vue";
import StandardSelection from "../views/forms/StandardSelection.vue";

// Admin
import AdminCompanyDetail from "../views/admin/AdminCompanyDetail.vue";
import AdminFormDetail from "../views/admin/AdminFormDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/forms/all",
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: {
      isPublic: true,
      layout: BlankLayout,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      isPublic: true,
      layout: BlankLayout,
    },
  },
  {
    path: "/email-action-handler",
    name: "EmailActionHandler",
    component: EmailActionHandler,
    meta: {
      isPublic: true,
      layout: BlankLayout,
    },
  },
  {
    path: "/verification-pending",
    name: "VerificationPending",
    component: VerificationPending,
    meta: {
      layout: BlankLayout,
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: ContactsListing,
  },
  {
    path: "/contacts/add",
    name: "Contact Add",
    component: ContactsListing,
    meta: {
      slideOut: ContactAdd
    }
  },
  {
    path: "/contacts/:id",
    name: "Contact Edit",
    component: ContactsListing,
    meta: {
      slideOut: ContactEdit
    }
  },
  {
    path: "/forms",
    name: "Forms",
    component: FormsListing,
    redirect: "/forms/all",
    children: [
      { path: "all", component: FormsListing},
      { path: "in-progress", component: FormsListing, meta: {status: 1}},
      { path: "complete", component: FormsListing, meta: {status: 2}}
    ]
  },
  {
    path: "/forms/:formId",
    name: "Form Detail",
    component: FormDetail,
  },
  {
    path: "/forms/:formId/:sectionId",
    name: "Section Detail",
    component: FormDetail,
    meta: {
      slideOut: SectionDetail
    }
  },
  {
    path: "/forms/1/3",
    name: "Qualification Approval",
    component: FormDetail,
    meta: {
      slideOut: QualificationApproval
    }
  },
  {
    path: "/forms/2/2",
    name: "Standard Selection",
    component: FormDetail,
    meta: {
      slideOut: StandardSelection
    }
  },
  {
    path: "/admin/:companyId",
    name: "Admin Company Detail",
    component: AdminCompanyDetail,
    meta: {
      adminOnly: true,
      layout: AdminLayout,
    }
  },
  {
    path: "/admin/:companyId/:formId",
    name: "Admin Form Detail",
    component: AdminFormDetail,
    meta: {
      adminOnly: true,
      layout: AdminLayout,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/forms/all",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requestedPath = to.fullPath;
  const isPublic = to.matched.some((record) => record.meta.isPublic);
  const isAuthenticated = auth.currentUser;
  const isVerified = auth.currentUser?.emailVerified;
  const isAdmin = auth.currentUser?.email === "quality@tquk.org";

  if (!isPublic && !isAuthenticated) {
    next("/sign-in?redirect=" + requestedPath);
  }
  else if (!isVerified && isAuthenticated && to.name !== "VerificationPending" && to.name !== "EmailActionHandler" ) {
    next("/verification-pending");
  }
  else {
    if ( to.meta.adminOnly && !isAdmin) {
        next("/");
    } else {
        next();
      }
    }  
});

export default router;
