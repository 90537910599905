// Awarding 
import { getOnboardingAwardingSchema } from "@/schemas/onboardingAwarding"
import { getCentreRecognitionSchema } from "@/schemas/centreRecognition"
import { getQualificationApprovalSchema } from "@/schemas/qualificationApproval"

// EPA
import { getOnboardingEpaSchema } from "@/schemas/onboardingEpa"
import { getEpaStandardSelectionSchema } from "@/schemas/epaStandardSelection"

export function getSectionSchema(formId: string, sectionId: string, section: any, contacts: any ) { 
    if (formId === "1") {
        switch (sectionId) {
            case "1":
                return getOnboardingAwardingSchema(section, contacts);
            case "2":
                return getCentreRecognitionSchema(section, contacts);
            case "3":
                return getQualificationApprovalSchema(section, contacts);
        }
    }
    if (formId === "2") {
        switch (sectionId) {
            case "1":
                return getOnboardingEpaSchema(section, contacts);
            case "2":
                return getEpaStandardSelectionSchema(section, contacts);
        }
    }
}