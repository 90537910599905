
import { defineComponent, ref } from "vue";
import firebase from "../firebase";
const auth = firebase.auth();

export default defineComponent({
  name: "VerificationPending",
  setup() {
    const message = ref("");

    const sendVerificationEmail = () => {
      auth.currentUser?.sendEmailVerification()
        .then(function() {
          message.value = "Please check your Email."
        })
        .catch(function(error) {
          message.value = error
        });
    }
    return { sendVerificationEmail, message };
  },
});
