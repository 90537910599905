export enum Status {
    "NOT_STARTED",
    "IN_PROGRESS",
    "COMPLETE"
}

export interface FieldSchema {
    component?: string;
	name: string;
	label?: string;
	type?: string;
	required?: boolean;
	validation?: any;
	title?: string;
	subtitle?: string;
	class?: string;
	value?: any;
	hidden?: boolean;
	group?: boolean;
	inputs?: Array<FieldSchema>;
	logic?: (values: any, schema: Array<FieldSchema>) => void;
}

export interface SectionSchema {
    title: string, 
    description: string, 
    code: string, 
    status: Status,
    progress: number,
    fields: FieldSchema[]
}

export interface FormSchema {
    title: string, 
    description: string, 
    code: string, 
    status: Status, 
    progress: number, 
    visible: true
    sections: SectionSchema[]
}